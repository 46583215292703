<template>
  <div v-if="shipment">
    <shipment-print-kk-v1 :shipment="shipment" />
  </div>
</template>
<script>
import axios from "axios";
import ShipmentPrintKkV1 from "./ShipmentPrintKkV1";
export default {
  name: "ShipmentPrint",
  components: { ShipmentPrintKkV1 },
  data() {
    return {
      code: null,
      shipment: null,
    };
  },
  props: {
    proShipment: {
      type: Object,
    },
  },
  beforeMount() {
    window.onafterprint = () => {
      this.$router.go(-1);
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    if (this.proShipment) {
      this.shipment = this.proShipment;
      this.$nextTick(() => {
        setTimeout(() => {
          window.print();
        }, 100);
      });
      return;
    }
    if (this.code) {
      axios.get("shipments/get/" + this.code).then((r) => {
        if (r) {
          this.shipment = r;
          this.shipment.from_branch = r.branch_branchToshipment_from_branch_id;
          this.shipment.to_branch = r.branch_branchToshipment_to_branch_id;
        }
      });
    }
  },
};
</script>

<style scoped>
@media screen {
  html.has-aside-left body {
    padding-left: 0 !important;
  }
}
</style>